import { addNewOrgPageData } from 'constants/data';

import EnrollmentPageWrapper from 'components/helperComponents/enrollmentPageWrapper/enrollmentPageWrapper';
import PageWrapper from 'components/helperComponents/pageWrapper/pageWrapper';
import { NotePopup } from 'modules/notes/notePopup';
import InviteExpiredPage from 'modules/oauth/invite/inviteExpired/inviteExpired';
import { Profile } from 'pages/clients/clientForm/profile';
import { Suspense, lazy } from 'react';
import 'react-big-calendar/lib/sass/styles.scss';
import { Navigate, Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { useAutoLogout, useBeforeExitLogout } from 'utils/hooks/useAutoLogout';
import { useReloadCacheLogout } from 'utils/useReloadCacheLogout';

// eslint-disable-next-line import/order
import { PERMISSION } from './utils/protectedRoutes/userRolesAndPermissionTypes';
// eslint-disable-next-line import/order
import PrivateRoutes from './utils/protectedRoutes';

import 'material-icons';
import 'react-toastify/dist/ReactToastify.css';

import { useAuth0 } from '@auth0/auth0-react';
import { Box } from '@mui/material';
import { LoadingSpinner } from 'components/loadingSpinner';
// eslint-disable-next-line import/order
import { LoginEmail } from 'components/loginEmail';
const Auth0Error = lazy(() => import('modules/oauth/errorPage/Auth0Error'));
const Auth0Permissions = lazy(() => import('modules/oauth/Auth0Permissions'));
const InvitePage = lazy(() => import('modules/oauth/invite/invitePage'));
const InvitePageStep2 = lazy(() => import('modules/oauth/invite/invitePageStep2'));
const RedirectToAuth0Login = lazy(() => import('modules/oauth/RedirectToAuth0Login'));
const ClientsPage = lazy(() => import('./pages/clients/clients'));
const PerformancePage = lazy(() => import('./pages/performance/performance'));

// import OcrScanner from 'components/ocr/ocr';
import OcrScannerAudio from 'components/ocrAudio/ocrAudio';
import useAccordionStateSync from 'utils/hooks/useAccordionState';

import { getLsFeaturesEnrollmentPlanner } from './modules/networkTools/localStorageTokens';
import './styles/global.scss';

const Page404 = lazy(() => import('./components').then((module) => ({ default: module.Page404 })));
const HomeProxyPage = lazy(() =>
  import('./pages').then((module) => ({ default: module.HomeProxyPage }))
);

const OrganizationsPage = lazy(() =>
  import('./pages').then((module) => ({ default: module.OrganizationsPage }))
);
const EnrollmentPlannerPage = lazy(() =>
  import('./pages').then((module) => ({ default: module.EnrollmentPlannerPage }))
);
const AboutPage = lazy(() => import('./pages/organizations/about/about'));
const ProgramSetupPage = lazy(
  () => import('./pages/organizations/programs/newProgram/programSetup')
);
const ProgramsPage = lazy(() => import('./pages/organizations/programs/programs'));
const UsersPage = lazy(() => import('./pages/organizations/users/users'));
const ImportSFTPPage = lazy(() => import('./pages/organizations/importSFTP/importSFTP'));
const IntegrationsPageEMR = lazy(
  () => import('./pages/organizations/integrations/integrationsEMR')
);
const ActivatePage = lazy(() => import('./pages/organizations/activate/activate'));
const ClientForm = lazy(() => import('./pages/clients/clientForm/clientForm'));
const OurWork = lazy(() => import('./pages/clients/clientForm/ourWork/ourWork'));

const SSOPage = lazy(() => import('./pages/organizations/sso/sso'));

function App() {
  useAutoLogout(15);

  const isEnrollmentVisible = getLsFeaturesEnrollmentPlanner();
  useReloadCacheLogout();
  useBeforeExitLogout();
  useAccordionStateSync();
  const { isLoading } = useAuth0();

  if (isLoading) {
    return (
      <Box
        sx={{ display: 'flex', height: '100vh', justifyContent: 'center', alignItems: 'center' }}
      >
        <LoadingSpinner />
      </Box>
    );
  }

  return (
    <div>
      <ToastContainer />
      <Suspense fallback={null}>
        <Routes>
          {/* PUBLIC ROUTES */}
          <Route path="/poc-audio" element={<OcrScannerAudio />} />
          <Route path="*" element={<Page404 />} />
          <Route path="/" element={<HomeProxyPage />} />
          {/*<Route path="/poc" element={<OcrScanner />} />*/}

          {/* POC auth0 */}
          {/* --------------- */}
          <Route path="/auth/login" element={<RedirectToAuth0Login />} />
          <Route path="/auth0" element={<LoginEmail />} />
          <Route path="/auth0/permissions" element={<Auth0Permissions />} />
          <Route path="/auth0Error" element={<Auth0Error />} />
          <Route path="invite/:login_hint?/:inviteCode?/:connection?" element={<InvitePage />} />
          <Route path="inviteStep2/" element={<InvitePageStep2 />} />

          {/* POC auth0 */}

          <Route path="/inviteExpired" element={<InviteExpiredPage />} />

          {/* PROTECTED ROUTES */}
          {isEnrollmentVisible && (
            <Route
              path="enrollmentPlanner"
              element={<PrivateRoutes allowedPermissions={[PERMISSION.ENROLL_READ]} />}
            >
              <Route
                path=""
                element={<EnrollmentPageWrapper pageContent={<EnrollmentPlannerPage />} />}
              />
            </Route>
          )}
          <Route
            path="organizations"
            element={<PrivateRoutes allowedPermissions={[PERMISSION.ORG_READ]} />}
          >
            <Route
              path=""
              element={
                <PageWrapper hasSideNavigation={false} pageContent={<OrganizationsPage />} />
              }
            />
            <Route
              path="about/:organizationID?/:editMode?"
              element={
                <PageWrapper subHeaderData={addNewOrgPageData} pageContent={<AboutPage />} />
              }
            />
            <Route
              path="programs/:organizationID/:editMode?"
              element={
                <PageWrapper subHeaderData={addNewOrgPageData} pageContent={<ProgramsPage />} />
              }
            />
            <Route
              path="sso/:organizationID?/:editMode?"
              element={<PageWrapper subHeaderData={addNewOrgPageData} pageContent={<SSOPage />} />}
            />
            <Route
              path="users/:organizationID?/:editMode?"
              element={
                <PageWrapper subHeaderData={addNewOrgPageData} pageContent={<UsersPage />} />
              }
            />
            <Route
              path="import/:organizationID?/:editMode?"
              element={
                <PageWrapper subHeaderData={addNewOrgPageData} pageContent={<ImportSFTPPage />} />
              }
            />
            <Route
              path="integrations/:organizationID?/:editMode?"
              element={
                <PageWrapper
                  subHeaderData={addNewOrgPageData}
                  pageContent={<IntegrationsPageEMR />}
                />
              }
            />
            <Route
              path="activate/:organizationID?/:editMode?"
              element={
                <PageWrapper subHeaderData={addNewOrgPageData} pageContent={<ActivatePage />} />
              }
            />
            <Route
              path="programs/new-program/:organizationID/:editMode?"
              element={
                <PageWrapper subHeaderData={addNewOrgPageData} pageContent={<ProgramSetupPage />} />
              }
            />
            <Route
              path="programs/:organizationID/:programID/:editMode"
              element={
                <PageWrapper subHeaderData={addNewOrgPageData} pageContent={<ProgramSetupPage />} />
              }
            />
          </Route>
          <Route
            path="clients"
            element={<PrivateRoutes allowedPermissions={[PERMISSION.CLIENTS_READ]} />}
          >
            <Route path="" element={<EnrollmentPageWrapper pageContent={<ClientsPage />} />} />
            <Route path="new" element={<EnrollmentPageWrapper pageContent={<ClientForm />} />}>
              <Route path="profile" element={<Profile />} />
              <Route path="" element={<Navigate to="profile" />} />
            </Route>
            <Route
              path="edit/:clientId"
              element={<EnrollmentPageWrapper pageContent={<ClientForm />} />}
            >
              <Route path="profile" element={<Profile />} />
              <Route path="ourWork" element={<OurWork />} />
              <Route path="" element={<Navigate to="ourWork" replace />} />
            </Route>
          </Route>
          <Route
            path="clients"
            element={<PrivateRoutes allowedPermissions={[PERMISSION.CLIENTS_READ]} />}
          >
            <Route path="edit/:clientId">
              <Route path="notes/:noteId" element={<NotePopup />}></Route>
            </Route>
          </Route>
          <Route
            path="reporting"
            element={<PrivateRoutes allowedPermissions={[PERMISSION.CLIENTS_READ]} />}
          >
            <Route path="" element={<EnrollmentPageWrapper pageContent={<PerformancePage />} />} />
          </Route>
        </Routes>
      </Suspense>
    </div>
  );
}

export default App;
