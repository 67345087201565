import { Grid } from '@mui/material';
import { Dropdown, ErrorContainer, InputField, LabelComponent, Stack } from 'components';
import AccordionComponent from 'components/accordion/accordion';
import RaceDropdown from 'components/dropdown/raceDropdown';
import { useFormikContext } from 'formik';
import { useAppSelector } from 'store/hooks';

import { FormValues } from '../../clientForm';
import { isSectionInvalid } from '../personalDetails/personalDetails';
const genders = [
  { id: 'Woman', name: 'Woman' },
  { id: 'Man', name: 'Man' },
  {
    id: 'Non-binary/Genderqueer/Gender nonconforming',
    name: 'Non-binary/Genderqueer/Gender nonconforming'
  },
  { id: 'Transgender', name: 'Transgender' },
  { id: 'Self-described', name: 'Self-described' }
];
const veteranStatus = [
  { id: 'true', name: ' Yes -- has been discharged from the armed forces of the United States' },
  { id: 'false', name: 'No' }
];
const racesList = [
  {
    id: 'AMERICAN_INDIAN_OR_ALASKA_NATIVE',
    name: 'American Indian Or Alaska Native'
  },
  {
    id: 'ASIAN',
    name: 'Asian'
  },
  {
    id: 'BLACK_OR_AFRICAN_AMERICAN',
    name: 'Black Or African American'
  },
  {
    id: 'HISPANIC_OR_LATINO',
    name: 'Hispanic Or Latino'
  },
  {
    id: 'MIDDLE_EASTERN_OR_NORTH_AFRICAN',
    name: 'Middle Eastern Or North African'
  },
  {
    id: 'NATIVE_HAWAIIAN_OR_OTHER_PACIFIC_ISLANDER',
    name: 'Native Hawaiian Or Other Pacific Islander'
  },
  {
    id: 'WHITE',
    name: 'White'
  },
  {
    id: 'SELF_DESCRIBED',
    name: 'Self Described'
  }
];

const Demographics = () => {
  const formik = useFormikContext<FormValues>();
  const insurances = useAppSelector((state) => state.clients.insurancesAutocomplete.data);
  const isSelfDescribed = formik.values.races?.filter((race) => race?.id === 'SELF_DESCRIBED')[0];
  const selfDescribed = formik?.values?.races?.find((race) => race?.id === 'SELF_DESCRIBED');

  //
  return (
    <AccordionComponent
      accordionTabId="fifth"
      title="Demographics"
      showWarning={isSectionInvalid(formik?.errors, [
        'insuranceId',
        'insurancePlan',
        'language',
        'veteranStatus',
        'genderIdentity',
        'races',
        'selfDescribed'
      ])}
    >
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={6}>
          <Stack width="100%">
            <LabelComponent text="Insurance Category" />
            <Dropdown
              value={formik.values.insuranceId ?? ''}
              name="insuranceId"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              label="Select"
              data={insurances ?? []}
            />
            <ErrorContainer
              visible={formik.touched.insuranceId && formik.errors.insuranceId}
              errors={formik.errors}
              name="insuranceId"
            />
          </Stack>
        </Grid>
        <Grid item xs={6}>
          <Stack width="100%">
            <LabelComponent text="Insurance Plan" />
            <InputField
              value={formik.values.insurancePlan}
              name="insurancePlan"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              placeholder="Enter"
            />
            <ErrorContainer
              visible={formik.touched.insurancePlan && formik.errors.insurancePlan}
              errors={formik.errors}
              name="insurancePlan"
            />
          </Stack>
        </Grid>
        <Grid item xs={6}>
          <Stack width="100%">
            <LabelComponent text="Primary language" />
            <InputField
              value={formik.values.language}
              name="language"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              placeholder="Enter"
            />
            <ErrorContainer
              visible={formik.touched.language && formik.errors.language}
              errors={formik.errors}
              name="language"
            />
          </Stack>
        </Grid>
        <Grid item xs={6}>
          <Stack width="100%">
            <LabelComponent text="Veteran status" />
            <Dropdown
              value={String(formik.values.veteranStatus) ?? ''}
              name="veteranStatus"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              label="Select"
              data={veteranStatus}
            />
            <ErrorContainer
              visible={formik.touched.veteranStatus && formik.errors.veteranStatus}
              errors={formik.errors}
              name="veteranStatus"
            />
          </Stack>
        </Grid>
        <Grid item xs={6}>
          <Stack width="100%">
            <LabelComponent text="Gender Identity" />
            <Dropdown
              value={formik.values.genderIdentity ?? ''}
              name="genderIdentity"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              label="Select"
              data={genders}
            />
            <ErrorContainer
              visible={formik.touched.genderIdentity && formik.errors.genderIdentity}
              errors={formik.errors}
              name="genderIdentity"
            />
          </Stack>
          {formik.values.genderIdentity === 'Self-described' && (
            <Stack>
              <LabelComponent text="Self-described Gender Identity" />
              <InputField
                name="genderSelfDescribed"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.genderSelfDescribed}
                placeholder="Type description"
              />
              <ErrorContainer
                visible={formik.touched.genderSelfDescribed && formik.errors.genderSelfDescribed}
                errors={formik.errors}
                name="genderSelfDescribed"
              />
            </Stack>
          )}
        </Grid>
        <Grid item xs={6}>
          <Stack width="100%">
            <LabelComponent text="Race" />
            <RaceDropdown
              value={
                // racesList?.map((race) => race?.id)
                formik.values.races?.map((race) => race?.id) ?? []
              }
              data={racesList}
              onChange={(e) => {
                let selectedRaces: any = e.target.value;
                selectedRaces = selectedRaces?.map((x: any) => {
                  return { id: x, name: x === 'SELF_DESCRIBED' ? '' : x };
                });
                formik.setFieldValue('races', [...selectedRaces]);
              }}
              label="Select"
              multiSelect
            />
            <ErrorContainer
              visible={formik.touched.races && formik.errors.races}
              errors={formik.errors}
              name="races"
            />
          </Stack>
          {!!isSelfDescribed && (
            <Stack width="100%">
              <LabelComponent text="Self-described Race" />
              <InputField
                value={formik.values.selfDescribed ?? selfDescribed?.name}
                name="selfDescribed"
                onChange={(e) => {
                  const updatedRaces = formik?.values?.races?.map((race) =>
                    race.id === 'SELF_DESCRIBED' ? { ...race, name: e.target.value } : race
                  );

                  // Update the formik values with the modified array
                  formik.setFieldValue('races', updatedRaces);
                  formik.handleChange(e);
                }}
                onBlur={formik.handleBlur}
                placeholder="Type description"
              />
              <ErrorContainer
                visible={formik.touched.selfDescribed && formik.errors.selfDescribed}
                errors={formik.errors}
                name="selfDescribed"
              />
            </Stack>
          )}
        </Grid>
      </Grid>
    </AccordionComponent>
  );
};

export default Demographics;
