import { Button } from 'components/button';
import React, { useState, useRef } from 'react';

import style from './ocrAudio.module.scss';
import { axiosPublic } from '../../modules/networkTools/networkTools';
import { notifyUserError, notifyUserSuccess } from '../../utils/notifications';
import { InputField } from '../inputField';

interface FileUploadResultProps {
  res: any;
}

type OcrRecorderProps = {
  onAudioRecorded?: (audioBlob: Blob) => void;
};

const OcrRecorderAudio: React.FunctionComponent<OcrRecorderProps> = ({ onAudioRecorded }) => {
  const [isRecording, setIsRecording] = useState(false);
  const [audioBlob, setAudioBlob] = useState<Blob | null>(null);
  const [audioURL, setAudioURL] = useState<string | null>(null);
  const [audioName, setAudioName] = useState<string>('');
  const mediaRecorderRef = useRef<MediaRecorder | null>(null);
  const audioChunksRef = useRef<Blob[]>([]);
  const audioRef = useRef<HTMLAudioElement | null>(null);

  const startRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      const mediaRecorder = new MediaRecorder(stream, { mimeType: 'audio/mp4' });
      mediaRecorderRef.current = mediaRecorder;

      audioChunksRef.current = [];

      mediaRecorder.ondataavailable = (event) => {
        audioChunksRef.current.push(event.data);
      };

      mediaRecorder.onstop = () => {
        const recordedAudioBlob = new Blob(audioChunksRef.current, { type: 'audio/mp4' });
        setAudioBlob(recordedAudioBlob);
        const audioUrl = URL.createObjectURL(recordedAudioBlob);
        setAudioURL(audioUrl);

        const tempAudio = new Audio(audioUrl);
        tempAudio.preload = 'metadata';

        if (onAudioRecorded) {
          onAudioRecorded(recordedAudioBlob);
        }
      };

      mediaRecorder.start();
      setIsRecording(true);
    } catch (err) {
      console.error('Error accessing microphone: ', err);
    }
  };

  const stopRecording = () => {
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
      setIsRecording(false);
    }
  };

  const handleDeleteAudio = () => {
    setAudioBlob(null);
    setAudioURL(null);
    audioChunksRef.current = [];
    setAudioName('');
  };

  const uploadToS3BucketForOCRAudio = async (file: any): Promise<FileUploadResultProps> => {
    try {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      };
      const bodyFormData = new FormData();

      bodyFormData.append('file', file);
      bodyFormData.append('filename', `${audioName}.mp4`);
      const res = await axiosPublic.post('health-scribe/upload', bodyFormData, config);
      notifyUserSuccess('Successfully uploaded');
      handleDeleteAudio();
      return { res: res };
    } catch (error: any) {
      notifyUserError(`Error  during upload ${error?.message}`);
      return { res: null };
    }
  };

  const sendAudio = async (blob: Blob) => {
    await uploadToS3BucketForOCRAudio(blob);
  };

  const handleSendAudioClick = async () => {
    if (audioBlob) {
      await sendAudio(audioBlob);
    }
  };

  return (
    <div className={style.ocrAudio}>
      {!audioURL && (
        <Button
          text={isRecording ? 'Stop Recording' : 'Start Recording'}
          theme="primary"
          customStyleClass={style.recordButton}
          handleOnClick={isRecording ? stopRecording : startRecording}
        />
      )}
      {audioURL && (
        <div className={style.audioPlayer}>
          <audio ref={audioRef} controls src={audioURL} preload="metadata" />
          <InputField
            placeholder="Set audio name"
            onChange={(e) => setAudioName(e.target.value)}
            onBlur={(e: any) => setAudioName(e.target.value)}
            value={audioName}
          />
          <div className={style.btnWrap}>
            <Button text="Cancel" theme="transparent" handleOnClick={handleDeleteAudio} />
            <Button
              text="Send"
              theme="primary"
              disabled={!audioName}
              handleOnClick={handleSendAudioClick}
            />
          </div>
          <a href={audioURL} download={`${audioName}.mp4`}>
            <Button text="Download Audio" theme="primary" />
          </a>
        </div>
      )}
    </div>
  );
};

export default OcrRecorderAudio;
