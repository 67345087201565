import { Button, Stack } from 'components';
import { useFormikContext } from 'formik';
import { useHasUnsavedChanges } from 'utils/hooks/useHasUnsavedChanges';

import { FormValues } from '../clientForm';
import { Demographics } from './demographics';
import { ContactInfo } from './emergencyContact';
import PersonalDetails from './personalDetails/personalDetails';
import { Referral } from './referral';
import { SupportSystem } from './supportSystem';

const Profile = () => {
  const { handleSubmit, submitCount, dirty, isValid, submitForm, isSubmitting } =
    useFormikContext<FormValues>();

  useHasUnsavedChanges(
    {
      isValid,
      submitForm
    },
    submitCount === 0 ? dirty : false
  );

  return (
    <form onSubmit={handleSubmit}>
      <Stack
        px="16px"
        py="28px"
        gap="32px"
        flex={8}
        sx={{
          overflow: 'scroll',
          maxHeight: '100vh'
        }}
      >
        <PersonalDetails />

        <ContactInfo />

        <SupportSystem />

        <Referral />

        <Demographics />

        <Stack
          width="100%"
          display="flex"
          flexDirection="row"
          flex={2}
          justifyContent="end"
          data-test-id="profile-info-save"
        >
          <Stack width="100px">
            <Button disabled={!isValid || isSubmitting} text="Save" theme="primary" />
          </Stack>
        </Stack>
      </Stack>
    </form>
  );
};

export default Profile;
