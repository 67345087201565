import { useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { resetAccordionState } from 'store/clients/clientsSlice';
import { useAppDispatch } from 'store/hooks';
// resetAccordionState
const useAccordionStateSync = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const params = useParams();

  useEffect(() => {
    const currentPath = location.pathname;

    // Check if the current path does not contain "ourWork" or "profile"
    if (!currentPath.includes('ourWork') && !currentPath.includes('profile')) {
      console.log('clear state');
      dispatch(resetAccordionState(null));
    }
  }, [location, params]);

  // Return anything you might want for future expansion (e.g., state variables or functions)
};

export default useAccordionStateSync;
