import { Stack, Tooltip } from 'components';
import { Button } from 'components/button';
import { Title } from 'components/title';
import { FC, ReactNode, useEffect, useRef, useState } from 'react';
import { updateAccordionState } from 'store/clients/clientsSlice';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import style from './accordion.module.scss';

interface AccordionProps {
  title: string;
  accordionTabId: 'first' | 'second' | 'third' | 'fourth' | 'fifth';
  children: ReactNode;
  useStack?: boolean;
  _isOpen?: boolean;
  showWarning?: boolean;
}
const HighPriorityIcon = () => {
  return (
    <div className={style.icon}>
      <span className={'material-icons'}>{`priority_high`}</span>
    </div>
  );
};
const AccordionComponent: FC<AccordionProps> = ({
  _isOpen = false,
  title,
  children,
  useStack = true,
  showWarning = false,
  accordionTabId
}) => {
  const dispatch = useAppDispatch();
  const accordionState = useAppSelector(
    (state) => state.clients.clientProfileAccordionState.accordionOpen
  );

  const [isOpen, setIsOpen] = useState(accordionState[accordionTabId]);
  const [height, setHeight] = useState('0px');
  const contentRef = useRef<HTMLDivElement>(null);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
    dispatch(
      updateAccordionState({
        currentAccordionTab: accordionTabId,
        currentAccordionValue: !isOpen
      })
    );
  };

  useEffect(() => {
    if (contentRef.current) {
      setHeight(isOpen ? `${contentRef.current.scrollHeight + 60}px` : '0px');
    }
  }, [isOpen]);

  return (
    <div>
      <div className={style.accordionHeader}>
        <div className={style.title}>
          <Title text={title} type={'h2'} />
        </div>

        <div className={style.collapse}>
          {showWarning && (
            <>
              <Tooltip
                placement={'top'}
                toolTipText={"There's required info in this section"}
                disableInteractive
                enterDelay={300}
                fullWidth
              >
                <HighPriorityIcon />
              </Tooltip>
            </>
          )}
          <Button
            handleOnClick={toggleAccordion}
            type="button"
            theme={'transparent'}
            icon={
              <span className={'material-icons'}>{`${
                isOpen ? 'keyboard_arrow_up' : 'keyboard_arrow_down'
              }`}</span>
            }
          />
        </div>
      </div>
      <div
        ref={contentRef}
        style={{
          maxHeight: height,
          overflow: 'hidden',
          transition: 'all 0.5s ease',
          padding: isOpen ? '10px' : '0px 10px'
          // border: isOpen ? '1px solid #ddd' : 'none'
        }}
      >
        {useStack && (
          <Stack
            px="16px"
            // py="28px"
            gap="40px"
            flex={8}
            sx={{
              overflow: 'scroll',
              // maxHeight: '100vh',
              height: 'auto'
            }}
          >
            {children}
          </Stack>
        )}
        {!useStack && <>{children}</>}
      </div>
    </div>
  );
};

export default AccordionComponent;
