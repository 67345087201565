import { Grid } from '@mui/material';
import {
  DatePicker,
  Dropdown,
  ErrorContainer,
  InputField,
  LabelComponent,
  Stack
} from 'components';
import AccordionComponent from 'components/accordion/accordion';
import { useFormikContext } from 'formik';
import { useEffect } from 'react';
import { getInsurancesAutocomplete, getLanguagesAutocomplete } from 'store/clients/clientsActions';
import { useAppDispatch } from 'store/hooks';

import { FormValues } from '../../clientForm';

const pronouns = [
  { id: 'She/Her/Hers', name: 'She/Her/Hers' },
  { id: 'He/Him/His', name: 'He/Him/His' },
  { id: 'They/Them/Theirs', name: 'They/Them/Theirs' },
  { id: 'Use name only', name: 'Use name only' },
  { id: 'Self-described', name: 'Self-described' }
];

export const isSectionInvalid = (
  errors: any,
  fieldsToVerify: string[],
  isArray?: boolean
): boolean => {
  if (isArray) {
    errors = errors?.reduce((acc: any, obj: any) => {
      return { ...acc, ...obj }; // Merge the properties of the current object into the accumulator
    }, {});
  }
  return !!fieldsToVerify?.find((x: string) => {
    const hasErrorInSection = errors?.[x];

    return hasErrorInSection;
  });
};
const PersonalDetails = () => {
  const dispatch = useAppDispatch();
  const formik = useFormikContext<FormValues>();

  useEffect(() => {
    dispatch(getLanguagesAutocomplete({ term: '' }));
    dispatch(getInsurancesAutocomplete());
  }, []);

  return (
    <AccordionComponent
      accordionTabId="first"
      _isOpen={true}
      title="Identifiers"
      showWarning={isSectionInvalid(formik?.errors, [
        'firstName',
        'lastName',
        'preferredName',
        'dob',
        'clientIdentifier',
        'last4Ssn',
        'pronouns',
        'pronounsSelfDescribed'
      ])}
    >
      <div data-test-id="profile-person-details">
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} mt={'24px'}>
          <Grid item xs={6}>
            <Stack width="100%">
              <LabelComponent text="First Name" mandatory />
              <InputField
                value={formik.values.firstName}
                name="firstName"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                placeholder="Enter"
              />
              <ErrorContainer
                visible={formik.touched.firstName && formik.errors.firstName}
                errors={formik.errors}
                name="firstName"
              />
            </Stack>
          </Grid>
          <Grid item xs={6}>
            <Stack width="100%">
              <LabelComponent text="Last Name" mandatory />
              <InputField
                value={formik.values.lastName}
                name="lastName"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                placeholder="Enter"
              />
              <ErrorContainer
                visible={formik.touched.lastName && formik.errors.lastName}
                errors={formik.errors}
                name="lastName"
              />
            </Stack>
          </Grid>
          <Grid item xs={6}>
            <Stack width="100%">
              <LabelComponent text="Preferred Name" />
              <InputField
                value={formik.values.preferredName}
                name="preferredName"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                placeholder="Enter"
              />
              <ErrorContainer
                visible={formik.touched.preferredName && formik.errors.preferredName}
                errors={formik.errors}
                name="preferredName"
              />
            </Stack>
          </Grid>
          <Grid item xs={6}>
            <Stack width="100%">
              <LabelComponent text="DOB" mandatory />
              <DatePicker
                value={formik.values.dob}
                onChange={(value: string) => {
                  // @ts-expect-error this is valid but the formik types are incorrect
                  formik.setFieldValue('dob', value).then(() => {
                    formik.setFieldTouched('dob', true);
                  });
                }}
                onBlur={() => formik.setFieldTouched('dob', true)}
                disableFuture
              />
              <ErrorContainer
                visible={formik.touched.dob && formik.errors.dob}
                errors={formik.errors}
                name="dob"
              />
            </Stack>
          </Grid>
          <Grid item xs={6}>
            <Stack width="100%">
              <LabelComponent text="MRN or Client Identifier" mandatory />
              <InputField
                value={formik.values.clientIdentifier}
                name="clientIdentifier"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                placeholder="0000000000"
              />
              <ErrorContainer
                visible={formik.touched.clientIdentifier && formik.errors.clientIdentifier}
                errors={formik.errors}
                name="clientIdentifier"
              />
            </Stack>
          </Grid>

          <Grid item xs={6}>
            <Stack width="100%">
              <LabelComponent text="Last 4 SSN" />
              <InputField
                value={formik.values.last4Ssn}
                name="last4Ssn"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                placeholder="0000"
                maxLength={4}
              />
              <ErrorContainer
                visible={formik.touched.last4Ssn && formik.errors.last4Ssn}
                errors={formik.errors}
                name="last4Ssn"
              />
            </Stack>
          </Grid>
          <Grid item xs={6}>
            <Stack width="100%">
              <LabelComponent text="Pronouns" />
              <Dropdown
                name="pronouns"
                value={formik.values.pronouns ?? ''}
                data={pronouns}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                label="Select"
              />
              <ErrorContainer
                visible={formik.touched.pronouns && formik.errors.pronouns}
                errors={formik.errors}
                name="pronouns"
              />
            </Stack>
            {formik.values.pronouns === 'Self-described' && (
              <Stack>
                <LabelComponent text="Self-described Pronouns" />
                <InputField
                  name="pronounsSelfDescribed"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.pronounsSelfDescribed}
                  placeholder="Type description"
                />
                <ErrorContainer
                  visible={
                    formik.touched.pronounsSelfDescribed && formik.errors.pronounsSelfDescribed
                  }
                  errors={formik.errors}
                  name="pronounsSelfDescribed"
                />
              </Stack>
            )}
          </Grid>
        </Grid>
      </div>
    </AccordionComponent>
  );
};

export default PersonalDetails;
